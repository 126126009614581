import React, { useState,useEffect,useRef} from "react";
import { Grid, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Style from "./style";
import SwitchComponent from "../Switch";
import MenuList from "../MenuList";
import { MenuDatas,accessibleMenuDatas } from "../MenuList/MenuDatas";
import {permissions as UserPermissionsAction} from "../../store/actions/authAction"
import {mapMenuData} from "../../components/MenuList/menulistRefine"
const SidebarComponent = (props) => {
  const { className, permissions } = props;
  const {userId,permissions:UserPermissions} = useSelector((s)=>s.auth)
  const dispatch = useDispatch();
  const [themeType, setThemeType] = useState(
    localStorage.getItem("themeType") || "light"
  );
  const [menuList,setMenuList] = useState([])
  //theme type saving
  const toggleThemeType = () => {
    const newThemeType = themeType === "light" ? "dark" : "light";
    setThemeType(newThemeType);
    localStorage.setItem("themeType", newThemeType); // Store the theme type in localStorage
  };
  const hasFetchedPermissions = useRef(false); 
  useEffect(() => {
    let ignore = false;

    // Only make the API call if userId exists and the API hasn't been called yet
    if (userId && !hasFetchedPermissions.current) {
      hasFetchedPermissions.current = true; // Mark as fetched

      dispatch(UserPermissionsAction({
        data: {},
        callback: () => {
          if (!ignore) {
            // Handle callback logic
          }
        }
      }));
    }

    // Cleanup function
    return () => {
      ignore = true;
    };
  }, [userId, dispatch]); 
  useEffect(()=>{
    if(userId!==null){
      const res  = mapMenuData(MenuDatas,UserPermissions)
      setMenuList(res)
    }
  },[UserPermissions])

  return (
    <Grid
      container
      direction={"column"}
      justifyContent={"space-between"}
      className={className}
    >
      <Grid className="menu_con">
        <MenuList items={menuList} permissions={permissions} />
      </Grid>
      <Grid className="themeChange">
        {/* <FormControlLabel
          value="themode"
          control={<Switch color="primary" size="small" />}
          label="Light mode"
          labelPlacement="start"
        /> */}
        <SwitchComponent
          label={themeType !== "dark" ? "Light Theme" : "Dark Theme"}
          onClick={() => {
            toggleThemeType();
          }}
          checked={themeType === "dark"}
        />
      </Grid>
    </Grid>
  );
};

const Sidebar = styled(SidebarComponent)(({ theme }) => Style(theme));

export default Sidebar;
