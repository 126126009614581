import React from "react";
import Affliate from "../containers/Referral/Affliate";
import Conversion from "../containers/Referral/Conversion/index";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import CombinedLayout from "../layouts/AgencyLayout";

const affiliateDashboardRoutes = [
  {
    path: "/affiliate",
    element: <ProtectedRoute />,
    children: [
      {
        element: <CombinedLayout />,
        children: [
          {
            path: "",
            children: [
              {
                path: "list",
                element:<Affliate/>
              },
              {
                path: "create",
                // element:
              },
              {
                path: "edit/:id",
                // element:
              },
            ],
          },
          {
            children: [
              {
                path: "Conversions",
                 element:<Conversion/>
              },
              // {
              //   path: "create",
              //   // element:
              // },
              // {
              //   path: "edit/:id",
              //   // element:
              // },
            ],
          },
          // {
          //   path: "commissions ",
          //   children: [
          //     {
          //       path: "",
          //       // element:
          //     },
          //     {
          //       path: "create",
          //       // element:
          //     },
          //     {
          //       path: "edit/:id",
          //       // element:
          //     },
          //   ],
          // },
          // {
          //   path: "payouts ",
          //   children: [
          //     {
          //       path: "",
          //       // element:
          //     },
          //     {
          //       path: "create",
          //       // element:
          //     },
          //     {
          //       path: "edit/:id",
          //       // element:
          //     },
          //   ],
          // },
        ],
      },
    ],
  },
];

export default affiliateDashboardRoutes;
