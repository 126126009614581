import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Avatar, BackIcon, CrossArrow } from '../../assets/svg';
import { Button, CircularProgress, Divider, List, ListItem, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSearch } from '../../store/actions/searchAction';
import styles from './style';
import moment from 'moment';
import { InvoiceIcon } from '../../assets/svg'; 
import { useNavigate } from 'react-router-dom';
import { ClickAwayListener } from '@material-ui/core';
import { setGlobalSearchData ,setSearchvalue} from '../../store/reducers/searchReducer';
import { highlightText } from '../../utils/appHelper';

const CustomListbox = React.forwardRef(function CustomListbox(props, ref) {
  return (
    <ul
      ref={ref}
      {...props}
      style={{
        maxHeight: 679,
        overflowY: 'auto',
        padding: 0,
        margin: 0,
        listStyle: 'none',
      }}
    />
  );
});


const CustomPaper = ({ searchText = "test", classes, children,  ...rest }) => {
  const navigate = useNavigate();
  const {globalSearch={data:[]},searchValue } = useSelector((state) => state.search);

  
  const handler = () => { navigate(`/detailedpage`)};

  return (
    <Paper {...(rest || {})} elevation={3}>
      <Grid container alignItems="center" justifyContent="space-between" className={classes.searchTextContainer}>
        <Typography className={classes.searchText}>
          {/* <IconButton>
          <BackIcon/>
          </IconButton> */}
         Showing results for {JSON.stringify(globalSearch.data.searchText)}
        </Typography>
        <Button className={classes.showAllButton} onClick={handler}>
          Show All Results
        </Button>
      </Grid>
      <Divider className={classes.divider} />
      {children}
    </Paper>
  );
};

const AutocompleteComponent = ({ classes }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [isLoading,setIsLoading ] = useState(false)
  const { agencies = { data: [] }, invoices = { data: [] }, users = { data: [] },globalSearch={data:[]},searchValue } = useSelector((state) => state.search);
  const { loadingSearch } = useSelector((state) => state.ui);
  const navigate =useNavigate();

  const agencyOptions = agencies.data.map(option => ({
    type: 'agency',
    id: option.id,
    agency_name: option.agency_name,
    agency_logo: option.agency_logo,
    agency_email: option.agency_email,
    created_on: option.created_on
  }));

  const invoiceOptions = invoices.data.map(option => ({
    type: 'invoice',
    id: option.id,
    invoice_number: option.invoice_number,
    invoice_logo: option.invoice_logo,
    customer_email: option.customer_email,
    customer_name: option.customer_name,
    due_date: option.due_date,
    invoice_currency: option.invoice_currency,
    created_on: option.created_on
  }));

  const userOptions = users.data.map(option => ({
    type: 'user',
    email: option.email,
    first_name: option.first_name,
    last_name: option.last_name,
    username: option.username,
    created_on: option.created_on
  }));


  const handleSearchTextChange = (event, newValue) => {
    const newSearchText = typeof newValue === 'string' ? newValue : '';
    setSearchText(newSearchText);
    dispatch(setSearchvalue(newSearchText));
    if (newSearchText.length >= 3) {
      setIsLoading(true); 
      dispatch(getSearch({ searchText: newSearchText }, setFalse));
      dispatch(setGlobalSearchData({ searchText: newSearchText }));
    }
  };
  function setFalse(){
    alert("see ")
    setIsLoading(false)
  }
  const handleSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate(`/detailedpage`)
      setOpen(false)
    }
  };
  
  const groupedOptions = {
    agencies: agencyOptions.slice(0, 3),
    invoices: invoiceOptions.slice(0, 3),
    users: userOptions.slice(0, 3)
  };

  const options = [
    ...groupedOptions.agencies.map(option => ({ ...option, group: 'Agencies' })),
    ...groupedOptions.invoices.map(option => ({ ...option, group: 'Invoices' })),
    ...groupedOptions.users.map(option => ({ ...option, group: 'Users' }))
  ];

  const getOptionLabel = (option) => {
    return  globalSearch.data.searchText
  };
  const handleOptionChange = (event, newValue) => {
    if (newValue) {
      setSearchText(
        newValue.type === 'invoice' ? newValue.invoice_number :
        newValue.type === 'agency' ? newValue.agency_name :
        `${newValue.first_name} ${newValue.last_name}`
      );
    }
  };
  const handleViewAll = (group) => {
    switch (group) {
      case 'Agencies':
        navigate(`/agencies/list?search=${encodeURIComponent(globalSearch.data.searchText)}`);
        break;
      case 'Invoices':
        navigate('/invoices');
        break;
      case 'Users':
        navigate(`/agencies/users?search=${encodeURIComponent(globalSearch.data.searchText)}`);
        break;
      default:
        console.error('Unknown group:', group);
    }
  };

  
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
    <Autocomplete
      open={open}
      onOpen={()=> setOpen(true)}
      onClose={(e) => {
        e.preventDefault();
        e.stopPropagation()
      }}
      className={classes.autocomplete}
      inputValue={searchText}
      onInputChange={handleSearchTextChange}
      onChange={handleOptionChange}
      options={options}
      autoHighlight
      onKeyDown={handleSearchKeyDown}
      PaperComponent={(props) => {
        const hasResults = options.length > 0;
        return hasResults ? (
          <CustomPaper
            searchText={searchValue}
            classes={classes}
            {...props}
          />
        ) : null;
      }}     
      ListboxComponent={CustomListbox}
      groupBy={(option) => option.group}
      renderGroup={(params) => (
        <List key={params.key} className={classes.groupItems}>
            <Grid container alignItems="center" justifyContent="space-between" className={"heading"}> 
              <Grid className={classes.groupHeader}>{params.group}</Grid>
              <Button variant="outlined" color="primary" onClick={() => handleViewAll(params.group)} className={classes.viewAllButton}>
                View All
                <CrossArrow className={classes.crossArrowIcon} />
              </Button>         
            </Grid>
            {params.children}
          </List>
      )}
      filterOptions={(options) => options.slice(0, 9)}
      renderOption={(props, option) => (
        <ListItem {...props} className={classes.option} role="option">
          <Grid container alignItems="center" spacing={1}>
          {option.type === 'invoice' && (
              <Grid container> 
                  <Grid item xs={2} className={classes.leftAlign}>
                       <InvoiceIcon className={classes.invoiceIcon} />
                      <Typography className={classes.invoicetext}>
                      {highlightText(option.invoice_number, globalSearch.data.searchText)}
                     </Typography>

                  </Grid>
                  <Grid item xs={2} className={classes.centerAlign}>
                         <Typography className={classes.invoicetext}>
                            {highlightText(option.customer_name,globalSearch.data.searchText)}
                          </Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.centerAlign}>
                       <Typography className={classes.invoicetext}>
                            {highlightText(option.customer_email,globalSearch.data.searchText)}
                          </Typography>
                 </Grid>
                  <Grid item xs={2} className={classes.centerAlign}>
                          <Typography className={classes.invoicetext}>
                            {highlightText(option.invoice_currency,globalSearch.data.searchText)}
                          </Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.rightAlign}>
                          <Typography className={classes.invoicetext}>
                            {highlightText(moment(option.due_date).format('MMM D, YYYY'),globalSearch.data.searchText)}
                          </Typography>
                   </Grid>
              </Grid>
          )}
            {option.type === 'agency' && (
                    <Grid container>
                      <Grid item xs={4} className={classes.leftAlign}>
                        <Avatar
                          src={option.agency_logo}
                          alt="Agency Logo"
                          className={classes.logo}
                        />
                        <Typography className={classes.agencytext}>
                          {highlightText(option.agency_name,globalSearch.data.searchText)}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className={classes.centerAlign}>
                        <Typography className={classes.agencytext}>
                          {highlightText(option.agency_email,globalSearch.data.searchText)}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className={classes.rightAlign}>
                        <Typography className={classes.agencytext}>
                          {highlightText(moment(option.created_on).format('MMM D, YYYY'),globalSearch.data.searchText)}
                        </Typography>
                      </Grid>
                    </Grid>
             )}

           {option.type === 'user' && (
                <Grid container>
                  <Grid item xs={4} className={classes.leftAlign}>
                  {highlightText(`${option.first_name} ${option.last_name}`, globalSearch.data.searchText)}
                  </Grid>
                  <Grid item xs={4} className={classes.centerAlign}>
                    <Typography className={classes.usertext}>
                      {highlightText(option.email,globalSearch.data.searchText)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.rightAlign}>
                    <Typography className={classes.usertext}>
                      {highlightText(moment(option.created_on).format('MMM D, YYYY'),globalSearch.data.searchTexts)}
                    </Typography>
                  </Grid>
                </Grid>
            )}

          </Grid>
        </ListItem>
      )}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.textField}
          placeholder="Search..."
          size="small"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <React.Fragment>
                {loadingSearch ? (
                  <InputAdornment position="start">
                    <CircularProgress color="inherit" size={20} />
                  </InputAdornment>
                ) : (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              </React.Fragment>
            ),         
            endAdornment: null
          }}
        />
      )}
    />
    </ClickAwayListener>
  );
};

export default withStyles(styles)(AutocompleteComponent);
