import { oldVersionGetRequest, oldVersionPostRequest as postRequest } from "../../utils/apiHelper";

export const flozySupportService = {
    
    getpagesSupport,
    createflowSupport
};


async function getpagesSupport(data) {
    return await postRequest(`support/pagesupport`, data);
  }

  async function createflowSupport(data) {
    return await postRequest(`support/createflow`, data);
  }
