import { createAsyncThunk } from "@reduxjs/toolkit";
import { searchService } from "../services/searchService"; 
import { loadingSearch, setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";


export const getSearch = createAsyncThunk(
    "search/searchAgencies",
    async (data,{ rejectWithValue, dispatch },cb) => {
      try {
        dispatch(loadingSearch())
        const response = await searchService.getSearchdatas(data);
        dispatch(loadingSearch())
        return response?.data;
      } catch (err) {    
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  );