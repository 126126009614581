export const mapMenuData = (menuData, permissions = []) => {
    return menuData.reduce((acc, menuItem) => {
      // Keep "Home" route always visible
      if (menuItem.name === "Home") {
        acc.push(menuItem);
        return acc;
      }
  
      // Ensure permissions is an array before using .find()
      const permissionModule = Array.isArray(permissions) && permissions.find((perm) => perm.id === menuItem.id);
  
      // Check if the module is present in the permissions and has full access
      if (permissionModule && permissionModule.all) {
        const newItem = { ...menuItem };
  
        // If there are children, filter them based on submodule permissions
        if (menuItem.children && permissionModule.submodules) {
          newItem.children = menuItem.children.filter((child) => {
            const submodulePermission = permissionModule.submodules.find(
              (sub) => sub.submodule_name.toLowerCase() === child.name.toLowerCase()
            );
            return submodulePermission && submodulePermission.all;
          });
        }
  
        // Add the module to the final menu if it has at least one child or no children
        if (!menuItem.children || newItem.children.length > 0) {
          acc.push(newItem);
        }
      }
  
      return acc;
    }, []);
  };
  