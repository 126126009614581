import React from "react";
import PropTypes from 'prop-types';
import { Avatar, Divider, Grid, IconButton, Select, styled, Tooltip, Typography } from "@mui/material";

// import Style
import Style from "./style";

// import Components
import CountCard from "../../components/CountCard";
import CardComponent from "../../components/CardComponent"
import { InfoIcon, MoreIcon } from "../../assets/svg";

const Home = (props) => {

    const { className } = props;

    return (
        <Grid container spacing={3} className={className}>
            <CountCard />
            <Grid item xs={12} md={6}>
                <CardComponent title={"New Agencies Joined"}>
                    ss
                </CardComponent>
            </Grid>
            <Grid item xs={12} md={3}>
                <CardComponent title={"Total Agencies"}>
                    ss
                </CardComponent>
            </Grid>
            <Grid item xs={12} md={3}>
                <CardComponent title={"Add-Ons"}>
                    ss
                </CardComponent>
            </Grid>
            <Grid item xs={12} md={6}>
                <CardComponent title={"Recent subscription"} actionBtn>
                   Table Component
                </CardComponent>
            </Grid>
            <Grid item xs={12} md={6}>
                <CardComponent title={"Agency Joined"} infoIcon>
                    ss
                </CardComponent>
            </Grid>
            <Grid item xs={12} md={7}>
                <CardComponent title={"Agencies Under Plan"}>
                    <Grid container justifyContent={"space-between"}>
                        <Typography variant="body1" color={"textSecondary"} className="flexAlign">
                        Plan Based Agency Numbers
                        <Tooltip arrow title={"Plan Based Agency Numbers"}><IconButton sx={{ p: 0, ml: 1 }}><InfoIcon /></IconButton></Tooltip>
                        </Typography>
                        <Grid className="flexAlign">
                            <Select size="small" />
                            <IconButton sx={{ml:2}}><MoreIcon /></IconButton>
                        </Grid>
                    </Grid>
                </CardComponent>
            </Grid>
            <Grid item xs={12} md={5}>
                <CardComponent title={"Agency Location"} infoIcon moreBtn>
                    ss
                </CardComponent>
            </Grid>
            <Grid item xs={12} md={6}>
                <CardComponent title={"Failed Payments"} actionBtn>
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid className="flexAlign">
                            <Grid>
                                <Typography variant="body1" color={"textSecondary"} className="fw-500" sx={{ pb: 1 }}>Total Amount</Typography>
                                <Typography variant="h4" className="fw-700">$1231.45</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="subtitle1" color={"primary.main"}>Total Agencies</Typography>
                    </Grid>
                    <Divider sx={{ mt: 2, mb: 2 }} />

                    {/* Loop this */}
                    <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{pb:1.5}}>
                        <Grid className="flexAlign">
                            <Avatar sx={{ width: '36px', height: '36px', mr: 1.5 }} />
                            <Grid>
                                <Grid className="flexAlign" sx={{pb:0.2}}>
                                <Typography variant="body1" color={"textSecondary"} className="fw-500">Charles Clark</Typography>
                                <Typography variant="body2" color={"primary.main"} sx={{pl:2}} className="fw-500 fs-12">12 Sep, 12:00pm</Typography>
                                </Grid>
                                <Typography variant="body2" color={"grey.light"} className="fs-12">nefarious_inker_75@gmail.com</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="body1" color={""} className="fw-600">$ 6234242</Typography>
                    </Grid>
                    {/* Loop this */}

                </CardComponent>
            </Grid>
            <Grid item xs={12} md={6}>
                <CardComponent title={"Disputed Payments"} actionBtn>
                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid className="flexAlign">
                            <Grid>
                                <Typography variant="body1" color={"textSecondary"} className="fw-500" sx={{ pb: 1 }}>Total Amount</Typography>
                                <Typography variant="h4" className="fw-700">$1231.45</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="subtitle1" color={"primary.main"}>Total Agencies</Typography>
                    </Grid>
                    <Divider sx={{ mt: 2, mb: 2 }} />

                    {/* Loop this */}
                    <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{pb:1.5}}>
                        <Grid className="flexAlign">
                            <Avatar sx={{ width: '36px', height: '36px', mr: 1.5 }} />
                            <Grid>
                                <Grid className="flexAlign" sx={{pb:0.2}}>
                                <Typography variant="body1" color={"textSecondary"} className="fw-500">Charles Clark</Typography>
                                <Typography variant="body2" color={"primary.main"} sx={{pl:2}} className="fw-500 fs-12">12 Sep, 12:00pm</Typography>
                                </Grid>
                                <Typography variant="body2" color={"grey.light"} className="fs-12">nefarious_inker_75@gmail.com</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="body1" color={""} className="fw-600">$ 6234242</Typography>
                    </Grid>
                    {/* Loop this */}

                </CardComponent>
            </Grid>
            <Grid item xs={12} md={6}>
                <CardComponent title={"Subscribers"} actionBtn>
                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid className="flexAlign">
                            <Grid>
                                <Typography variant="body1" color={"textSecondary"} className="fw-500" sx={{ pb: 1 }}>Total Amount</Typography>
                                <Typography variant="h4" className="fw-700">$1231.45</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="subtitle1" color={"primary.main"}>Total Agencies</Typography>
                    </Grid>
                    <Divider sx={{ mt: 2, mb: 2 }} />

                    {/* Loop this */}
                    <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{pb:1.5}}>
                        <Grid className="flexAlign">
                            <Avatar sx={{ width: '36px', height: '36px', mr: 1.5 }} />
                            <Grid>
                                <Grid className="flexAlign" sx={{pb:0.2}}>
                                <Typography variant="body1" color={"textSecondary"} className="fw-500">Charles Clark</Typography>
                                <Typography variant="body2" color={"primary.main"} sx={{pl:2}} className="fw-500 fs-12">12 Sep, 12:00pm</Typography>
                                </Grid>
                                <Typography variant="body2" color={"grey.light"} className="fs-12">nefarious_inker_75@gmail.com</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="body1" color={""} className="fw-600">$ 6234242</Typography>
                    </Grid>
                    {/* Loop this */}

                </CardComponent>
            </Grid>
            <Grid item xs={12} md={6}>
                <CardComponent title={"Successful Payments"} actionBtn>
                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid className="flexAlign">
                            <Grid>
                                <Typography variant="body1" color={"textSecondary"} className="fw-500" sx={{ pb: 1 }}>Total Amount</Typography>
                                <Typography variant="h4" className="fw-700">$1231.45</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="subtitle1" color={"primary.main"}>Total Agencies</Typography>
                    </Grid>
                    <Divider sx={{ mt: 2, mb: 2 }} />

                    {/* Loop this */}
                    <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{pb:1.5}}>
                        <Grid className="flexAlign">
                            <Avatar sx={{ width: '36px', height: '36px', mr: 1.5 }} />
                            <Grid>
                                <Grid className="flexAlign" sx={{pb:0.2}}>
                                <Typography variant="body1" color={"textSecondary"} className="fw-500">Charles Clark</Typography>
                                <Typography variant="body2" color={"primary.main"} sx={{pl:2}} className="fw-500 fs-12">12 Sep, 12:00pm</Typography>
                                </Grid>
                                <Typography variant="body2" color={"grey.light"} className="fs-12">nefarious_inker_75@gmail.com</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="body1" color={""} className="fw-600">$ 6234242</Typography>
                    </Grid>
                    {/* Loop this */}

                </CardComponent>
            </Grid>
        </Grid>
    )
}

Home.defaultProps = {
    classes: {}
};

Home.propTypes = {
    className: PropTypes.string
};

export default styled(Home)(Style);