import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import FlozySupport from "../containers/FlozySupport";




const FlozySupportRoutes = [
    {
        path:"/flozysupport",
        element:<ProtectedRoute/>,
        children:[{
            path:'',
            element:<FlozySupport/>
        },
        
    ]
    }
]

export default  FlozySupportRoutes