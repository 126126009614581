import { createSlice } from "@reduxjs/toolkit";
import { getpagessupport } from "../actions/flozySupportAction";

const initialState = {
    list: {
        results:[],
        headers: [
            { field: 'agency_name', label: 'Agency Name' },
            { field: 'user_name', label: 'User Name' },
            { field: 'ticket_no', label: 'Ticket No' },
            { field: 'answers', label: 'Answers' }, 
            { field: 'created_on', label: 'Requested on' }, 
            { field: "action", label: "ACTION" },
      
           
          ],
          pagination: {
            count: 10,
            next: null,
            previous: null,
            page_size: 10,
            num_pages: 0,
            page_number: 1,
          },
          search: "",
          ordering: "created_on",
    orderBy: [
      { field: "created_on", label: "Requested on" },
      { field: "agency_name", label: "Agency Name" },
     

    ],
    },
    loading:null
};
export const supportSlice = createSlice({
    name: "support",
    initialState,
    reducers: {
      setPageSize(state, action) {
        console.log(action.payload,"Seee here payload")
        state.list.pagination.page_size = action.payload;
      },
      setPage(state, action) {
        state.list.pagination.page_number = action.payload;
      },
      setSearch(state, action) {
        state.list.pagination.page_number = 1;
        state.list.search = action.payload;
      },
      setOrdering(state, action) {
        state.list.ordering = action.payload;
      },
      addFilterRule(state, action) {
        state.list.filter.rules.push({
          index: (Math.max(state.list.filter.rules.map((m) => m.index)) || 0) + 1,
          field: "",
          operator: "",
          value: "",
        });
      },
      removeFilterRule(state, action) {
        state.list.filter.rules = state.list.filter.rules.filter(
          (f) => f.index !== action.payload
        );
      },
      updateFilterRule(state, action) {
        state.list.filter.rules = state.list.filter.rules.map((m) => {
          if (m.index === action.payload.index) {
            return action.payload;
          }
          return m;
        });
      },
      applyFilter(state, action) {
        state.list.pagination.page_number = 1;
        state.list.filter.params = action.payload;
      },
      clearFilter(state) {
        state.list.pagination.page_number = 1;
        state.list.filter.params = "";
        state.list.filter.rules = [{ index: 0, field: "", value: "" }];
      },
    },
    extraReducers: (builder) => {
      // add cases for getAgencies api calls
      builder
        .addCase(getpagessupport.pending, (state) => {
          state.loading = true;
        })
        .addCase(getpagessupport.fulfilled, (state, { payload }) => {
          state.list.pagination = {
            ...state.list.pagination,
            ...payload.data.pagination,
          };
  
          state.list.results = payload.data.results
          state.loading = false;
        })
        .addCase(getpagessupport.rejected, (state) => {
          state.loading = false;
        });
    },
  });
  
  export const {
    setPage,
    setPageSize,
    setSearch,
    setOrdering,
    addFilterRule,
    removeFilterRule,
    updateFilterRule,
    applyFilter,
    clearFilter,
  } = supportSlice.actions;
  
  export default supportSlice.reducer;