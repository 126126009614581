import {
  MenuHome,
  MenuAgency,
  MenuSales,
  MenuEmail,
  MenuUser,
  MenuSttings,
  LicenseDashboard,
  FeatureDashboard,
  AgencyListIcon,
  Userslogo,
  FeatureIcon,
  FeatureFlag,
  UserMangment,
  AffiliateDashboard,
  Affiliates,
  Conversions,
  Commissions,
  Payouts
} from "../../assets/svg/index";

// Function to map menu data based on user permissions


// Permissions data (as example input)
const permissionsData = {
  permissions: [
    {
      id: 1, // Matches Agency Dashboard
      all: true,
      read: true,
      write: true,
      delete: true,
      submodules: [
        {
          id: 1,
          all: true,
          read: true,
          write: true,
          delete: true,
          submodule_name: "Agencies List"
        },
        {
          id: 2,
          all: true,
          read: true,
          write: true,
          delete: true,
          submodule_name: "Users"
        }
      ],
      module_name: "Agency Dashboard"
    },
    {
      id: 2, // Matches Product
      all: true,
      read: true,
      write: true,
      delete: true,
      submodules: [
        {
          id: 5,
          all: true,
          read: true,
          write: true,
          delete: true,
          submodule_name: "Addons"
        },
        {
          id: 6,
          all: true,
          read: true,
          write: true,
          delete: true,
          submodule_name: "Feature"
        }
      ],
      module_name: "Product"
    }
  ]
};

// Simplify permissions for easier access (if necessary)
const simplifiedPermissions = permissionsData.permissions;

// Example MenuDatas array (defined globally)
export const MenuDatas = [
  {
    icon: <MenuHome />,
    name: "Home",
    link: "/home",
  },
  {
    icon: <MenuAgency />,
    name: "Agency Dashboard",
    link: "/agencies",
    id: 1, // matches permission id for Agency Dashboard
    permission: (p) => Object.values(p?.agencies || []).indexOf(true) >= 0,
    children: [
      {
        icon: <AgencyListIcon />,
        name: "Agencies List",
        link: "/agencies/list",
        permission: (p) => p?.agencies?.agencies_list === true,
      },
      {
        icon: <Userslogo />,
        name: "Users",
        link: "/agencies/users",
        permission: (p) => p?.agencies?.users_list === true,
      },
      {
        icon: <MenuUser />,
        name: "Invitations",
        link: "/invite",
        // permission: (p) => p?.agencies?.users_list === true,
      }
    ],
  },
  // {
  //   icon: <MenuSales />,
  //   name: "Sales Dashboard",
  //   link: "/sales",
  //   permission: (p) => Object.values(p?.sales || []).indexOf(true) >= 0,
  //   children: [
  //     {
  //       icon: <MenuUser />,
  //       name: "Customer payments",
  //       link: "/sales/customer",
  //       permission: (p) => p?.sales?.payment_list === true,
  //     },
  //     {
  //       icon: <MenuUser />,
  //       name: "Reports",
  //       link: "/sales/reports",
  //       permission: (p) => p?.sales?.reports_list === true,
  //     },
  //   ],
  // },
  // {
  //   icon: <MenuEmail />,
  //   name: "Email Campaign",
  //   link: "emailCampaign",
  // },
  // {
  //   icon: <MenuUser />,
  //   name: "User Management",
  //   link: "/users",
  //   permission: (p) => Object.values(p?.sales_users || []).indexOf(true) >= 0,
  //   children: [
  //     {
  //       icon: <MenuUser />,
  //       name: "Users Group",
  //       link: "usergroup",
  //       permission: (p) => p?.sales_users?.group_list === true,
  //     },
  //   ],vscode-webview://1kp5bdl53l96cq5ea7l0atpvs8hodf7kon6im5n43bcccsfetmli/workspace/37a54670-eba0-4eb7-b6d9-fac6820a85d8/request/34440247-5900c72d-f63e-40df-92fb-550d061e26d9
  // },
  {
    icon: <AffiliateDashboard />,
    name: "Affiliate Dashboard",
    link: "/affiliate",
    id:4,
    children: [
      {
        icon: <Affiliates/>,
        name: "Affiliate",
        link: '/affiliate/list',
      },
      {
        icon: <Conversions />,
        name: "Conversions",
        link: '/affiliate/Conversions',
      },
      {
        icon:<Commissions/>,
        name: "Commissions",
        link: '/affiliate/Commissions ',
      },
      {
        icon:<Payouts/>,
        name: "Payouts",
        link: '/Payouts',
      },
    ],
  },
  {
    icon: <MenuAgency />,
    name: "Product",
    link: "/products",
    id: 2, // matches permission id for Product
    permission: (p) => Object.values(p?.agencies || []).indexOf(true) >= 0,
    children: [
      {
        icon: <LicenseDashboard />,
        name: "Licence",
        link: '/products/licence',
        permission: (p) => p?.agencies?.agencies_list === true,
      },
      {
        icon: <FeatureDashboard />,
        name: "Addons",
        link: '/products/addons',
        permission: (p) => p?.agencies?.users_list === true,
      },
      {
        icon: <FeatureIcon />,
        name: "Feature",
        link: '/products/feature',
        permission: (p) => p?.agencies?.users_list === true,
      },
      {
        icon: <FeatureFlag />,
        name: "Feature Flags",
        link: '/flags',
        // permission: (p) => p?.agencies?.users_list === true,
      },
    ],
  },
  {
    icon: <UserMangment />,
    name: "User Management",
    link: '/userManagement',
  },
  {
    icon: <UserMangment />,
    name: "Flozy Support",
    link: '/flozysupport',
    id: 5
  },

];

// Map the MenuDatas based on the simplified permissions
// export const accessibleMenuDatas = mapMenuData(MenuDatas, simplifiedPermissions);

// Log the result to see the filtered accessible menu

