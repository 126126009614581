import { createAsyncThunk } from "@reduxjs/toolkit";
import {subscriptionService} from "../services/subscriptionsService"
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";

export const cancelSubscriptionAsync = createAsyncThunk("subscriptons/cancel", async (data, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Addons creating..."))
        const response = await subscriptionService.cancelSubscriptions (data);
        dispatch(setLoading(null))
        return response?.data
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
})

export const refundSubscriptionAsync = createAsyncThunk("subscriptions/refund", async (data, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setLoading("Processing refund..."));
        const response = await subscriptionService.refundSubscription(data).then(()=>{
            console.log("reoaded above")
            window.location.reload();
        });
        dispatch(setLoading(null));
        return response?.data;
    } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err.response || err, dispatch);
        return rejectWithValue(err);
    }
}
);