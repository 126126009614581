import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";
import { referralService } from "../services/referralService";

export const getaffliate = createAsyncThunk(
  "agencies/getAgencies",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Fetching Agencies..."));
      const response = await referralService.getaffliate(data);
      dispatch(setLoading(null));
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);

export const getconversions = createAsyncThunk(
  "agencies/getAgencies",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      console.log("pikasoooo",data)
      dispatch(setLoading("Fetching Agencies..."));
      const response = await referralService.getconversions(data);
      dispatch(setLoading(null));
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);