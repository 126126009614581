import { oldVersionGetRequest as getRequest, oldVersionPostRequest as postRequest } from "../../utils/apiHelper";

export const agenciesService = {
  getAgencies,
  getAgencyById,
  getAgencyDeatil,
  getAllAgencyDetail
};

//!important  while creating new service add type as module and subtype as submodule for access value
async function getAgencies(data) {
  return await postRequest(`agency/getAll`,data);
}

async function getAgencyById(param) {
  return await getRequest(`agency/${param}`)
}

async function getAgencyDeatil(id, detail,stripe_id,subscription_id, data) {
  return await postRequest(`agency/agencyClient?id=${id}&detail=${detail}&stripe_id=${stripe_id}&subscription_id=${subscription_id}`, data);
}
async function getAllAgencyDetail(param = "") {
  return await getRequest(`agency/getAllAgencyList?agencySearch=${param}`)
}