import React from "react";
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import Style from "./style";
import { BuildingIcon, MailIcon, UserIcon } from "../../../assets/svg";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getuserinfo, passwordreset } from "../../../store/actions/UserAction";
import { useEffect, useState } from "react";
import moment from 'moment';
import { CustomDialog } from "../../../components/DialogComponent";
import { EditIcon2 } from "../../../assets/svg";
const UserInfo = (props) => {
  const { className } = props;
  const { list } = useSelector((state) => state.userinfo);
  //const { loading, list } = useSelector((state) => state.agencies);
  const { userInfo, ownedAgencies, workspace, reset } = list;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [agencyCount, setAgencyCount] = useState(6);
  const [workspaceCount, setWorkspaceCount] = useState(6);
  const [openDialog, setOpenDialog] = useState(false);
  const role = userInfo?.user_role ? JSON.parse(userInfo?.user_role) : {};

  useEffect(() => {
    dispatch(getuserinfo({ id }))
  }, [dispatch, id])
  //topCard
  const handlePasswordReset = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = (confirm) => {
    setOpenDialog(false);
    if (confirm) {
      dispatch(passwordreset({ email: userInfo.email, name: userInfo.name }));
    }
  };



  const joined_date = moment(userInfo.created_on ? userInfo.created_on : userInfo.modified_on).format('DD-MM-YYYY');

  return (
    <Grid
      container
      alignItems={"center"}
      className={className}
    >
      
      <CustomDialog openDialog={openDialog} handleDialogClose={handleDialogClose} userInfo={userInfo} />

      <Grid item xs={12} className="userGroupContainer">
      <Grid>
                <Grid className="dflex aCenter jEnd mb-1">
                  <Typography variant="body2" className="fs-10" color={"text.greyText5"}>Joined Date :</Typography>
                  <Typography variant="body2" className="fs-10 fw-500" color={"text.greyText4"}>{joined_date}</Typography>
                </Grid>
              </Grid>
        {/* user group name */}
        <Grid container>
          <Grid item xs={12} md={4} className="pr-3">
            <Typography variant="body1" className="fw-600" sx={{ pb: 2 }}>User Name</Typography>
            <Typography variant="body2" className="fw-500" color={"text.greyText4"}>User name and the joined date in to the mentioned agency.</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container justifyContent={"space-between"}>
              <Grid item className="dflex aCenter">
                <Avatar src={userInfo.agency_logo} sx={{ width: 64, height: 64 }} />

                <Grid sx={{ ml: 2 }}>
                  <Typography variant="h4" className="mb-1">{userInfo.name}</Typography>
                  <Typography variant="body2" className="dflex aCenter"><UserIcon /><span className="ml-1">{"@" + userInfo.username}</span> </Typography>
                </Grid>

                <Grid sx={{ ml: 4, mt: 4 }}>

                  <Typography variant="body2" className="dflex aCenter"><MailIcon />
                    <span className="ml-1">{userInfo.email}
                      <IconButton>
                      <EditIcon2 />
                    </IconButton>
                    </span>
                  </Typography>
                </Grid>
                <Grid className="Password_Reset">
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      ml: 2,
                      backgroundColor: '#2563EB',
                      border: '2px solid',
                      borderColor: '#2563EB',
                      fontSize: '0.875rem',
                      color: '#FFFFFF',
                      '&:hover': {
                        backgroundColor: '#D0E7FE',
                        borderColor: '#1E50C1',
                      },
                      position: 'relative',
                    }}
                    onClick={handlePasswordReset}>
                    Reset Password</Button>

                </Grid>

              </Grid>
              
            </Grid>
          </Grid>
        </Grid>
      
        {/* user group name */}
        <Divider sx={{ mt: 5, mb: 5, width: '100%' }} />

        {/* Owned Agencies */}
        <Grid container>
          <Grid item xs={12} md={4} className="pr-3">
            <Typography variant="body1" className="fw-600" sx={{ pb: 2 }}>Owned Agencies</Typography>
            <Typography variant="body2" className="fw-500" color={"text.greyText4"}>These are the agencies owned by the above user</Typography>
          </Grid>
          {ownedAgencies?.length > 0 ?
            <>
              <Grid item xs={12} md={8}>
                <Grid container spacing={3}>

                  {Object.entries(ownedAgencies).slice(0, agencyCount).map(([key, value]) => {
                    return (
                      <Grid item xs={12} md={4}>
                        <Grid container justifyContent={"space-between"} alignItems={"center"} className="agenciesItem">
                          <Grid className="dflex aCenter">
                            <Avatar src={value.agency_logo} sx={{ width: 40, height: 40 }} />
                            <Grid className="pl-1">
                              <Typography variant="body2" className="fw-600">{value.agency_name}</Typography>
                              <Typography variant="body2" color={"text.greyText4"} className="dflex mt5 fs-10"><BuildingIcon /><span className="ml-1">{value.category}</span></Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>)
                  })}

                  {ownedAgencies?.length - 1 >= 6 ?
                    <Typography className="jEnd dflex primary1 w-100 cursorPointer" onClick={() => { agencyCount === 6 ? setAgencyCount(ownedAgencies?.length - 1) : setAgencyCount(6) }} > {agencyCount === 6 ? `Show more (${ownedAgencies?.length - 6})` : "Show Less"}</Typography>
                    : ""}
                </Grid>
              </Grid>
            </> : <Grid className="dflex aCenter"><Typography>No owned agencies</Typography></Grid>}
        </Grid>
        {/* Owned Agencies */}

        <Divider sx={{ mt: 5, mb: 5, width: '100%' }} />

        <Grid container>
          <Grid item xs={12} md={4} className="pr-3">
            <Typography variant="body1" className="fw-600" sx={{ pb: 2 }}>User in Other Agency</Typography>
            <Typography variant="body2" className="fw-500" color={"text.greyText4"}>The above user might be a member in other agency as client, user & admin. Here we can change the user role</Typography>
          </Grid>
          {workspace?.length > 0 ? <>
            <Grid item xs={12} md={8}>

              <Grid container justifyContent={"space-between"} alignItems={"center"} className="otherAgenciesHeader">
                <Typography variant="body2" className="fw-500 uppercase" color="text.greyText5">Agency Name</Typography>
                <Typography variant="body2" className="fw-500 uppercase" color="text.greyText5">USer Role</Typography>
              </Grid>

              {
                workspace.slice(0, workspaceCount).map((item) => {
                  const userType = role[item.id]?.type
                  return (
                    <Grid container justifyContent={"space-between"} alignItems={"center"} className="otherAgenciesItem">
                      <Grid className="dflex aCenter">

                        <Avatar src={item.agency_logo} sx={{ width: 40, height: 40 }} />

                        <Grid className="pl-1">
                          <Typography variant="body2" className="fw-600">{item.agency_name}</Typography>
                          <Typography variant="body2" color={"text.greyText4"} className="dflex mt5 fs-10"><BuildingIcon /><span className="ml-1">{item.category}</span></Typography>
                        </Grid>
                      </Grid>
                      <Typography variant="body2" className="fs-12 500 dflex" color={"text.greyText1"}><UserIcon /><span className="ml-1">{userType}</span></Typography>
                    </Grid>
                  )
                })
              }
              {workspace.length - 1 >= 6 ? <Typography className="jEnd dflex primary1 cursorPointer" onClick={() => { workspaceCount === 6 ? setWorkspaceCount(workspace?.length) : setWorkspaceCount(6) }} > {workspaceCount === 6 ? `Show more (${workspace?.length - 6})` : "Show Less"}</Typography>
                : ""}

            </Grid>
          </> : <Grid className="dflex aCenter"><Typography>User is not in other agencies</Typography></Grid>}

        </Grid>
      </Grid>
    </Grid>
  );
};

// default props
UserInfo.defaultProps = {
  classes: {}
};

// prop types
UserInfo.propTypes = {
  classes: PropTypes.object
};


export default styled(UserInfo)(Style);
