const Style = (theme) => ({
  width: 270,
  height: "100%",
  background: theme.palette.containers.sidebar,
  padding: "16px 5px 24px 5px",
  ".MuiListItem-root": {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    "& .MuiButtonBase-root": {
      width: "100%",
    },
  },

  "& .sub-menus": {
    width: "100%",
    paddingLeft: "12px",
  },
  "& .MuiListItemIcon-root": {
    minWidth: "40px",
  },
  "& .MuiListItemButton-root": {
    padding: "8px 24px",
  },


  ".active": {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.grey.lightest,
    borderRadius: "8px",
    "& .MuiListItemIcon-root": {
      "& svg": {
        "& path": {
          stroke: theme.palette.grey.lightest, // This sets the stroke color
          fill: "none" // This makes the fill transparent or you can set a specific color
        }
      }
    }
  },
  ".nonActive": {
    color: theme.palette.secondary.main
  },
  ".activeChild": {

    backgroundColor: `${theme.palette.containers.sidebar} !important`,
    "& .MuiListItemIcon-root": {
      "& svg": {
        "& path": {
          stroke: theme.palette.primary.main, // This sets the stroke color
          fill: "none" // This makes the fill transparent or you can set a specific color
        }
      }
    }
  },
  ".nonActiveChild": {

    "& .MuiListItemIcon-root": {
      "& svg": {
        "& path": {
          stroke: theme.palette.secondary.main, // This sets the stroke color
          fill: "none" // This makes the fill transparent or you can set a specific color
        }
      }
    }

  },
".menu_con":{
  overflowY:"scroll",
  height:"650px"
}


});

export default Style;
