import { oldVersionGetRequest, oldVersionPostRequest as postRequest } from "../../utils/apiHelper";

export const referralService = {
    getaffliate,
    getconversions
};


async function getaffliate(data) {
  console.log("pikasoooo",data)
    return await postRequest(`referrals/getReferralTable`, data);
  }

  async function getconversions(data) {
    console.log("pikasoooo",data)
      return await postRequest(`referrals/getconversions`, data);
    }