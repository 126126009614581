import { postRequest } from "../../utils/apiHelper";

export const authService = {
    login,
    permissions
}

async function login(data) {
    return await postRequest('auth/login', data)
}
async function permissions(data) {
    return await postRequest('auth/permissions', data)
}