import React, { Suspense } from "react";
import { useOutlet, useLocation, Link } from "react-router-dom";
import Loader from "../components/Loader";
import { ArrowIcon } from "../assets/svg";

const getBreadcrumbs = (path, id) => {
    // Return empty breadcrumbs for /agencies/list and /agencies/users
    if (path === "/agencies/list" || path.startsWith("/agencies/users")) {
        return [];
    }

    // Define the breadcrumbs structure for agencies and products
    const breadCrumbs = {
        [`/agencies/${id}`]: [
            { label: "Agencies", route: `/agencies` },
            { label: "Agencies List", route: `/agencies/list` },
            { label: "Agency Info", route: `/agencies/edit/${id}` }
        ],
        [`/agencies/userinfo/${id}`]: [
            { label: "Agencies", route: `/agencies` },
            { label: "Users List", route: `/agencies/users` },
            { label: "User Info", route: `/agencies/userinfo/${id}` }
        ],
        [`/products/licence/create`]: [
            { label: "License", route: `/products/licence` },
            { label: "Create License", route: `/products/licence/create` },
        ],
        [`/products/licence/edit/${id}`]: [
            { label: "License", route: `/products/licence` },
            { label: "Edit License", route: `/products/licence/edit/${id}` },
        ],
        [`/products/addons/create`]: [
            { label: "Addons", route: `/products/addons` },
            { label: "Create Addon", route: `/products/addons/create` },
        ],
        [`/products/addons/edit/${id}`]: [
            { label: "Addons", route: `/products/addons` },
            { label: "Edit Addon", route: `/products/addons/edit/${id}` },
        ],
        [`/products/feature/create`]: [
            { label: "Feature", route: `/products/feature` },
            { label: "Create Feature", route: `/products/feature/create` },
        ],
        [`/products/feature/edit/${id}`]: [
            { label: "Feature", route: `/products/feature` },
            { label: "Edit Feature", route: `products/feature/edit/${id}` },
        ],
    };

    return breadCrumbs[path] || [];
};
const CombinedLayout = () => {
    const outlet = useOutlet();
    const location = useLocation();

    // Extract the last segment (which could be 'create' or an ID)
    const pathSegments = location.pathname.split("/");
    const id = pathSegments[pathSegments.length - 1];
    
    const breadcrumbs = getBreadcrumbs(location.pathname, id);

    return (
        <div>
            {breadcrumbs?.length > 0 && (
                <nav className="breadcrumb" style={{ paddingLeft: "32px", marginTop: "20px" }}>
                    {breadcrumbs.map((breadcrumb, index) => (
                        <span key={index} className="breadcrumb-item" style={{ color: "black" }}>
                            <Link to={breadcrumb.route} className="breadcrumb-link" style={{ fontWeight: breadcrumb.route === location.pathname ? "bold" : "bold", textDecoration: "none", color: breadcrumb.route === location.pathname ? "black" : "rgb(185 189 204)" }}>
                                {breadcrumb.label}
                            </Link>
                            {index < breadcrumbs.length - 1 && (
                                <span className="breadcrumb-separator"> <ArrowIcon /> </span>
                            )}
                        </span>
                    ))}
                </nav>
            )}
            <Suspense fallback={<Loader open={"Loading Files"} />}>{outlet}</Suspense>
        </div>
    );
};

export default CombinedLayout;
