import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage("user", true);
    const navigate = useNavigate();

    // call this function when you want to authenticate the user
    const login = async (data) => {
        setUser(data);
        navigate("/home");
    };

    // call this function to sign out logged in user
    const logout = async () => {
        setUser(null);
        // Optionally, show a loading indicator
        await new Promise(resolve => setTimeout(resolve, 100)); // Simulate an async operation
        navigate("/auth/login", { replace: true });
    };
    
    

    const value = useMemo(
        () => ({
            user,
            login,
            logout,
            setUser
        }),
        // eslint-disable-next-line
        [user]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};