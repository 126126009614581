import axios from "axios";
import { getAccessToken } from "./appHelper";
const API_HOST = process.env.REACT_APP_API_HOST;
const API_HOST1 = process.env.REACT_APP_API_HOST_1
export const getRequest = async (url, options = {}) => {
  return await axios.get(API_HOST + url, {
    ...(options || {}),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      ...(options.headers || {}),
    },
  });
};

export const postRequest = async (url, data = {}, options = {}) => {
  return await axios.post(API_HOST + url, data, {
    ...(options || {}),
    headers: {
       Authorization: `Bearer ${getAccessToken()}`,

      ...(options.headers || {}),
    },
  });
};


export const deleteRequest = async (url, options = {}) => {
  return await axios.delete(API_HOST + url, {
    ...(options || {}),
    headers: {
      ...(options.headers || {}),
      // Additional headers can be set here if needed
    },
  });
};

export const updateRequest = async (url, data = {}, options = {}) => {
  return await axios.put(API_HOST + url, data, {
    ...(options || {}),
    headers: {
      ...(options.headers || {}),
      // Additional headers can be set here if needed
    },
  });
};
// v1 api's
export const oldVersionPostRequest = async (url, data = {}, options = {},type = 0, subType = 0) => {
  const token = getAccessToken()
  if(token){
    return await axios.post(API_HOST1 + url, data, {
      ...(options || {}),
      headers: {
        Authorization: `Bearer ${token}`,
        permissionType:type,
        permissionSubType:subType,
        ...(options.headers || {}),
      },
    });
  }
  
};

export const oldVersionGetRequest = async (url, options = {},type,subType) => {
  return await axios.get(API_HOST1 + url, {
    ...(options || {}),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      permissionType:type,
      permissionSubType:subType,
      ...(options.headers || {}),
    },
  });
};

export const oldVersionDeleteRequest = async (url, options = {}) => {
  return await axios.delete(API_HOST1 + url, {
    ...(options || {}),
    headers: {
      ...(options.headers || {}),
      // Additional headers can be set here if needed
    },
  });
};

export const oldVersionUpdateRequest = async (url, data = {}, options = {}) => {
  return await axios.put(API_HOST1 + url, data, {
    ...(options || {}),
    headers: {
      ...(options.headers || {}),
      // Additional headers can be set here if needed
    },
  });
};