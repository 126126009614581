import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";
import {authService} from "../services/authService"; // Adjust the import as necessary

export const login = createAsyncThunk(
  "auth/login",
  async ({ data, callback }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading("Logging In..."));
      const response = await authService.login(data);
      dispatch(setLoading(null));

      // Call the callback with success data
      if (callback) {
        callback(response?.data?.accessToken);
      }

      return response.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      // Call the callback with error data if needed
      if (callback) {
        callback(null, err);
      }
      return rejectWithValue(err);
    }
  }
);
export const permissions = createAsyncThunk(
  "auth/permissions",
  async ({ data, callback }, { dispatch, rejectWithValue }) => {
    try {
    
      const response = await authService.permissions();

      // Call the callback with success data

      return response.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      // Call the callback with error data if needed
      if (callback) {
        callback(null, err);
      }
      return rejectWithValue(err);
    }
  }
);
