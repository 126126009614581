import { createHashRouter, Navigate } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import homeRoutes from "./homeRoutes";
import loginRoutes from "./loginRoutes";
import agenciesRoutes from "./agenciesRoutes";
import salesRoutes from "./salesRoutes";
import userRoutes from "./userRoutes";
import invoicesRoutes from "./invoicesRoutes";
import productRoutes from "./productRoute";
import agencyAddonsRoutes from "./agencyAddonsRoute";
import inviteRoutes from "./inviteRoutes";
import productsRoutes from "./productsRoutes";
import userManagementRoutes from "./userManagementRoute";
import FlozySupportRoutes from "./flozysupportRoutes";
import SearchRoutes from "./searchRoutes";
import affiliateDashboardRoutes from "./affiliateDashboardRoute";


/**
 * Main Routes for the Application
 */
const router = createHashRouter([
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      ...loginRoutes,
      {
        path: '', 
        element: <Navigate to="/home" /> 
      },
      ...homeRoutes,
      ...agenciesRoutes,
      ...salesRoutes,
      ...userRoutes,
      ...invoicesRoutes,
      ...productsRoutes,
     ...productRoutes,
     ...agencyAddonsRoutes,
     ...inviteRoutes,
     ...userManagementRoutes,
     ...FlozySupportRoutes,
     ...SearchRoutes,
     ...affiliateDashboardRoutes,
    ],
  },
]);

export default router;
